@use '@angular/material' as mat;

// always include only once per project
@include mat.core();

$bafa-color: (
  primary: #0778a5,
  dev: #00ccaa,
  test: #ffc819,
  primary-light: #99c8dd,
  primary-dark: #004c76,
  secondary: #3a3a3a,
  secondary-light: #646464,
  secondary-dark: #141414,
  alert: #ffc819,
  alert-light: #fffb58,
  alert-dark: #c79800,
  error: #c1121c,
  error-light: #c1121c,
  error-dark: #c1121c,

  contrast: (
      primary: white,
      primary-light: rgba(black, 0.87),
      primary-dark: white,
      secondary: white,
      secondary-light: white,
      secondary-dark: white,
      alert: rgba(black, 0.87),
      alert-light: rgba(black, 0.87),
      alert-dark: rgba(black, 0.87),
      error: white,
    ),
);

$bafa-color-dark: (
  primary: #0778a5,
  dev: #00ccaa,
  test: #ffc819,
  primary-light: #99c8dd,
  primary-dark: red,
  secondary: #3a3a3a,
  secondary-light: #646464,
  secondary-dark: #141414,
  alert: #ffc819,
  alert-light: #fffb58,
  alert-dark: #c79800,
  error: #c1121c,
  error-light: #c1121c,
  error-dark: #c1121c,

  contrast: (
      primary: white,
      primary-light: rgba(black, 0.87),
      primary-dark: white,
      secondary: white,
      secondary-light: white,
      secondary-dark: white,
      alert: rgba(black, 0.87),
      alert-light: rgba(black, 0.87),
      alert-dark: rgba(black, 0.87),
      error: white,
    ),
);

$bafa-primary: mat.m2-define-palette(
  $bafa-color,
  primary,
  primary-light,
  primary-dark
);

$bafa-primary-dark: mat.m2-define-palette(
  $bafa-color-dark,
  primary,
  primary-light,
  primary-dark
);

$bafa-dev: mat.m2-define-palette($bafa-color, dev, primary-light, primary-dark);
$bafa-test: mat.m2-define-palette(
  $bafa-color,
  test,
  primary-light,
  primary-dark
);
$bafa-accent: mat.m2-define-palette(
  $bafa-color,
  secondary,
  secondary-light,
  secondary-dark
);
$bafa-alert: mat.m2-define-palette($bafa-color, alert, alert-light, alert-dark);
$bafa-warn: mat.m2-define-palette($bafa-color, error, error-light, error-dark);

$bafa-sans-typography: mat.m2-define-typography-config(
  $font-family: 'BundesSansWeb, Calibri, helvetica, sans-serif',
  $headline-1: mat.m2-define-typography-level(34px, 40px, 400),
  $subtitle-2: mat.m2-define-typography-level(20px, 28px, 400),
  $subtitle-1: mat.m2-define-typography-level(16px, 24px, 400),
  $headline-2: mat.m2-define-typography-level(26px, 40px, 400),
  $body-2: mat.m2-define-typography-level(16px, 24px, 500),
  $body-1: mat.m2-define-typography-level(15px, 20px, 400),
  $caption: mat.m2-define-typography-level(14px, 20px, 400),
  $button: mat.m2-define-typography-level(15px, 15px, 700),
);

@include mat.core();
@include mat.all-component-typographies($bafa-sans-typography);
@include mat.typography-hierarchy($bafa-sans-typography);

@mixin mat-core($bafa-sans-typography) {
  @include angular-material-typography($bafa-sans-typography);
  @include mat-ripple();
  @include cdk-a11y();
  @include cdk-overlay();
  @include cdk-text-field();
}

mat.$theme-ignore-duplication-warnings: true;

@media (prefers-color-scheme: light) {
$bafa-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $bafa-primary,
      accent: $bafa-accent,
      warn: $bafa-warn,
    ),
    typography: $bafa-sans-typography,
    // This line
    density: 0,
  )
);
@include mat.all-component-themes($bafa-theme);
}

@media (prefers-color-scheme: dark) {
$bafa-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $bafa-primary-dark,
      accent: $bafa-accent,
      warn: $bafa-warn,
    ),
    typography: $bafa-sans-typography,
    // This line
    density: 0,
  )
);
@include mat.all-component-themes($bafa-theme);
}

.bafa-serif {
  font-family: BundesSerifWeb, Cambria, 'Times New Roman', serif;
}

$dev: mat.m2-get-color-from-palette($bafa-dev);
$test: mat.m2-get-color-from-palette($bafa-test);
$primary: mat.m2-get-color-from-palette($bafa-primary);
$accent: mat.m2-get-color-from-palette($bafa-accent);
$alert: mat.m2-get-color-from-palette($bafa-alert);

.background-primary {
  background-color: $primary !important;
}

.background-dev {
  background-color: $dev !important;
}

.background-test {
  background-color: $test !important;
}

.alert {
  background: $alert;
  color: rgba(black, 0.87);
  font-weight: 500;
}

.success {
  background: $primary;
  color: white;
  font-weight: 500;
}