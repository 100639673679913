@use '@angular/material' as mat;
@use 'variables' as var;
@use 'theme' as *;

// example - get color from variables
// background-color: var.$color-grey-2;
// example - get color from theme
//background-color: mat.get-theme-color($bafa-theme, primary) !important;

.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
  color: unset !important;
  background-color: unset !important;
}

.mat-mdc-floating-label {
  width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.mdc-text-field {
  background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.p-timeline-event-opposite {
  flex: 0.3 !important;
}

.mat-mdc-card-title {
  font-size: 26px !important;
}

.mat-radio-button {
  margin-left: 10px;
  margin-bottom: 10px;
}

.mat-mdc-header-cell,
.mat-mdc-cell {
  font-size: 15px !important;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (prefers-color-scheme: light) {
  .mat-mdc-header-cell {
    font-size: 13px !important;
    color: var.$color-table-black;
    background-color: var.$color-white !important;
  }

  .mat-mdc-card {
    background-color: inherit !important;
  }

  .primary-dark {
    color: #004c76;
  }
}

@media (prefers-color-scheme: dark) {
  .mat-mdc-button:not(:disabled) {
    color: var.$color-white !important;
  }

  confirm-delete-account {
    .mat-mdc-header-cell {
      font-size: 13px !important;
      background-color: var.$color-table-gray !important;
    }
  }

  .p-timeline-event-connector {
    background: var.$color-white !important;
  }

  .mat-caption {
    color: var.$color-white !important;
  }

  .mat-mdc-card {
    border-width: 1px !important;
    border-color: white !important;
    background-color: inherit !important;
  }

  .primary-dark {
    color: red;
  }
}

.mat-mdc-header-row {
  position: sticky;
  top: 0;
  background-color: inherit;
}

.mat-subtitle-2 {
  padding: 20px 0px 16px 0px !important;
}

.mat-caption {
  color: #666666;
}

.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-stroked-button,
.mat-mdc-menu-item,
.mat-mdc-mini-fab,
.mat-mdc-button-wrapper,
.mat-mdc-raised-button,
.mat-mdc-flat-button {
  cursor: pointer !important;
  outline: 0px !important;
}

login {
  .mat-mdc-card-none-shadow {
    box-shadow: none !important;
    padding: 16px !important;
    flex: 1 1 100%;
  }
}

.mat-mdc-table {
  width: 100%;
  white-space: nowrap;
  background-color: inherit !important;
}

antrag-overview,
account-audit-overview,
account-overview {
  .mat-mdc-card-title {
    font:
      400 34px/60px BundesSerifWeb,
      Cambria,
      'Times New Roman',
      serif !important;
  }

  .mat-mdc-text-field-wrapper {
    height: 42px !important;
  }
  .mat-mdc-form-field-flex {
    margin-top: -6px !important;
  }

  .mat-mdc-card {
    box-shadow: none !important;
    background-color: inherit !important;
    border-color: inherit;
    border-width: 0px !important;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }
  .mdc-text-field--filled {
    background-color: transparent !important;
  }

  .mat-mdc-card-content {
    padding: 0px !important;
    background-color: inherit;
  }
  .mdc-notched-outline {
    display: none !important;
  }
  .mat-mdc-paginator .mat-mdc-select {
    width: 50% !important;
  }
  .mat-mdc-icon-button .mat-focus-indicator {
    width: 40px !important;
    height: 40px !important;
  }
  .mat-mdc-icon-button .mat-mdc-button-base {
    width: 40px !important;
    height: 40px !important;
  }
  .mdc-text-field {
    padding: 0px !important;
  }
  .mat-mdc-table .mat-mdc-header-cell {
    padding: 0px 0px 0px 15px !important;
  }
}

.mat-mdc-paginator {
  background-color: inherit !important;
}

@media (prefers-color-scheme: dark) {
  .none-shadow,
  .mat-card-none-shadow,
  .mat-mdc-card-none-shadow {
    box-shadow: none !important;
    background-color: inherit !important;
    border-color: inherit;
    border-width: 0px !important;
  }
}


.mdc-text-field {
    padding: 0px !important;
}

account-overview {
  .mat-mdc-card-title {
    font:
      400 34px/60px BundesSerifWeb,
      Cambria,
      'Times New Roman',
      serif !important;
  }

  .mat-mdc-card {
    box-shadow: none !important;
    background-color: inherit !important;
    border-color: inherit;
    border-width: 0px !important;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    padding: 5px 0px 0px 0px !important;
    width: 40px !important;
    height: 40px !important;
  }

  .mat-mdc-table .mat-mdc-header-cell {
    padding: 0px 0px 0px 15px !important;
  }

  .mdc-notched-outline {
    display: none !important;
  }

  .mat-mdc-paginator .mat-mdc-form-field-flex {
    width: 70% !important;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }

  .mat-mdc-text-field-wrapper {
    height: 42px !important;
  }
  .mat-mdc-form-field-flex {
    margin-top: -1px !important;
  }

  .mat-mdc-card-content {
    padding: 0px !important;
  }

  .mdc-text-field--filled {
    background-color: transparent !important;
  }
}

person-detail {
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }
  .mat-mdc-card-content {
    padding: 0px !important;
  }
  .mdc-text-field--filled {
    background-color: transparent !important;
    height: 48px !important;
  }
  .mdc-text-field {
    padding: 0px !important;
  }
  .mat-mdc-form-field-infix {
    background-color: transparent !important;
  }
  .mat-mdc-icon-button {
    background-color: transparent !important;
  }
}

change-password {
  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0px !important;
  }
}

email-exist {
  .mat-mdc-card-content {
    padding: 0px !important;
  }
}

logout {
  .mat-form {
    padding: 16px !important;
  }
}
