$color-grey-1: #666666;
$color-grey-2: #00008a;
$color-link-blue: #0072a3;
$color-green: green;
$color-red: red;
$color-orange: orange;
$color-notification: #ff7700;
$color-white: #ffffff;
$color-table-black: #0000008a;
$color-table-gray: #424242;

