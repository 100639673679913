@font-face {
  font-family: 'BundesSansWeb';
  src:
    /* Pretty Modern Browsers */
    url('assets/BundesSansWeb/BundesSansWeb_release_2013-04-25/BundesSansWeb-Regular.woff') format('woff'),
    /* Safari, Android, iOS */
    url('assets/BundesSansWeb/BundesSansWeb_release_2013-04-25/BundesSansWeb-Regular.ttf')  format('truetype');
}

@font-face {
  font-family: 'BundesSerifWeb';
  src:
    /* Pretty Modern Browsers */
    url('assets/BundesSerifWeb/BundesSerifWeb_release_2013-04-25/BundesSerifWeb-Regular.woff') format('woff'),
    /* Safari, Android, iOS */
    url('assets/BundesSerifWeb/BundesSerifWeb_release_2013-04-25/BundesSerifWeb-Regular.ttf')  format('truetype');
}
