@use 'variables' as var;

html,
body {
  height: 100%;
  box-sizing: border-box;
  margin: 0;
}

.card-actions {
  display: flex !important;
  justify-content: space-between;
  margin-right: 0 !important;
}

.dialog-box {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  padding: 16px;
  border-radius: 4px;
}

.big-icon {
  font-size: 42px !important;
  width: 42px !important;
  height: 42px !important;
}

.menu-button {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.visibility-hidden {
  visibility:hidden;
}

.bottom {
  vertical-align: bottom;
}

.table-cell {
  display: table-cell !important;
}

.mat-hint,
.mat-error {
  font-size: 12px;
}

.mat-dialog-actions {
  justify-content: flex-end;
  padding-right: 24px !important;
  padding-bottom: 16px !important;
}

.mat-dialog-container {
  display: block;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
  box-shadow: none !important;
}

.button-action-style {
  margin-top: 20px !important;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0;
  border-radius: 4px;
  overflow: visible;
  text-transform: uppercase;
}

.close-button {
  margin-right: 40px;
}

/*** Colors ***/

.color-link {
  color: var.$color-link-blue;
}

.material-icons.color_green {
  color: var.$color-green;
}
.material-icons.color_red {
  color: var.$color-red;
}
.material-icons.color_orange {
  color: var.$color-orange;
}

.material-icons.color_gray {
  color: var.$color-grey-1;
}

.text-color_orange {
  color: var.$color-orange;
}

/*** Table ***/

.auto-column-width {
  table-layout: auto;
}

.button-cell {
  text-overflow: unset;
}

tr.mat-row:hover {
  background-color: whitesmoke;
}

.break-spaces {
  white-space: break-spaces;
}

/*** Loading Spinner ***/

.loading-shade {
  position: absolute;
  top: 250px;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  background: rgba(0, 0, 0, 0);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-label {
  font-weight: lighter;
  font-size: 11px;
  line-height: 1;
}

.header-data {
  font-size: 13px;
  line-height: 1.5;
}

.overview-dialog-content-container {
  overflow: auto !important;
  max-height: 800px;
}

.table-container {
  position: relative;
  min-height: 200px;
  overflow: auto;
}

.delete-overview-table-container {
  position: relative;
  min-height: 150px;
  max-height: 350px;
  overflow: auto;
}

@media only screen and (min-height: 900px) {
  body {
    overflow-y: hidden;
  }
}
